<template>
  <div class="become-author" v-if="user">
    <div class="container">
      <transition name="page-fade" mode="out-in">
        <author-form
          v-if="!authorFormSent && user"
          @form-sent="handleFormSent($event)"
          v-bind="$t('become-author')"
          :user="user"
          :is-loading="isLoading"
        />

        <div class="become-author__box" v-else>
          <base-notification-box
            mode="warning"
            :heading="$t('become-author.box.heading')"
          >
            <p>{{ $t("become-author.box.message") }}</p>
            <p class="become-author__box-mail">{{ email }}</p>
            <template slot="actions">
              <base-button
                :title="$t('become-author.box.panel')"
                to="/dashboard"
              >
                {{ $t("become-author.box.panel") }}
              </base-button>
            </template>
          </base-notification-box>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import auth from '@/api/services/auth';

export default {
  name: 'BecomeAuthor',
  data: () => ({
    authorFormSent: false,
    user: null,
    email: '',
    isLoading: false,
  }),
  methods: {
    async handleFormSent({ userData, success }) {
      this.isLoading = true;
      const { isError } = await this.$store.dispatch('auth/updateUserData', {
        ...userData,
        email: userData.email.toLowerCase(),
        documents: userData.imageId,
      });
      if (isError) {
        this.isLoading = false;
        return;
      }
      this.authorFormSent = success;
      this.email = userData.email;

      try {
        await auth.sendAuthorDocuments({
          documents: userData.imageId,
        });
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const user = await auth.getUserData();
      this.user = user.data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>
<style lang="scss" src="./BecomeAuthor.scss" scoped />
